.booking-new-module {
    padding: 60px 0;
}
.booking-wizard-head {
    background: $__white;
    box-shadow: 0px 4px 24px 0px #E1E1E140;
    border-radius: 5px;
    padding: 24px;
    margin-bottom: 24px;
    .booking-head-title {
        position: relative;
        z-index: 1;
        h4 {
            font-size: $__font__size__20;
            margin-bottom: 5px;
        }
        p {
            color: $__gray_light;
            font-size: $__font__size__14;
            margin-bottom: 0;
        }
        &::after {
            content: "";
            width: 100px;
            height: 100px;
            border-top: 1px solid #F4F4F4;
            border-right: 1px solid #F4F4F4;
            position: absolute;
            right: 150px;
            top: -20px;
            z-index: -1;
            transform: rotate(45deg);
            @include respond-below(custom1399) {
                right: 100px;
            }
            @include respond-below(custom1199) {
                right: 0px;
            }
            @include respond-below(custom991) {
                display: none;
            }
        }
    }
}
.booking-wizard-lists {
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-below(custom991) {
            margin-top: 20px;
        }
        @include respond-below(custom575) {
            flex-direction: column;
        }
        li {
            text-align: center;
            position: relative;
            min-width: 140px;
            margin-right: 30px;
            z-index: 1;
            @include respond-below(custom1399) {
                min-width: 130px;
                margin-right: 0px;
            }
            @include respond-below(custom767) {
                min-width: 90px;
            }
            @include respond-below(custom575) {
                margin-bottom: 30px;
            }
            &:last-child {
                @include respond-below(custom575) {
                    margin-bottom: 0;
                }
            }
            span {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $__black-gray;
                margin: 0 auto 10px;
            }
            h6 {
                color: $__gray_light;
                font-size: $__font__size__14;
                font-weight: $__normal;
                margin-bottom: 0;
            }
            &.active {
                span {
                    background: $__primarycolor;
                }
                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    border: 1px dashed $__primarycolor;
                    position: absolute;
                    left: 55%;
                    top: 40%;
                    z-index: -1;
                    @include respond-below(custom991) {
                        left: 75%;
                    }
                    @include respond-below(custom575) {
                        transform: rotate(90deg);
                        left: 0;
                        top: 100%;
                    }
                }
                &.activated {
                    span {
                        img {
                            display: none;
                        }
                        &::after {
                            content: "\f058";
                            color: $__white;
                            display: block;
                            font-family: "font awesome 6 free";
                            font-weight: 900;
                            font-size: 20px;
                            line-height: normal;
                        }
                    }
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
.booking-sidebar {
    margin-bottom: 24px;
    .booking-sidebar-card {
        background: $__white;
        box-shadow: 0px 4px 24px 0px #E1E1E140;
        border-radius: 5px;
        margin-bottom: 20px;
        .booking-sidebar-head {
            padding: 15px;
            border-bottom: 1px solid $__blue__light;
            h5 {
                color: $__black-gray;
            }
            .coupon-view {
                color: $__dark__blues;
                font-size: $__font__size__14;
            }
            a {
                color: $__gray_light;
                font-size: $__font__size__14;
                &:hover {
                    color: #127384;
                }
            }
        }
        .booking-sidebar-body {
            padding: 15px;
            .booking-car-detail {
                display: flex;
                align-items: center;
                padding: 15px;
                .car-img {
                    width: 120px;
                    height: 120px;
                    border-radius: 5px;
                    display: flex;
                    margin-right: 14px;
                    flex-shrink: 0;
                    @include respond-below(custom575) {
                        width: 80px;
                        height: 80px;
                    }
                    img {
                        width: 100%;
                        border-radius: 5px;
                    }
                }
                .care-more-info {
                    h5 {
                        color: $__black-gray;
                        font-weight: $__medium;
                        margin-bottom: 5px;
                    }
                    p {
                        color: $__gray_light;
                        font-size: $__font__size__14; 
                        margin-bottom: 20px;
                    }
                    a {
                        color: $__dark__blues;
                        font-size: $__font__size__14;
                    }
                }
            }
            .booking-vehicle-rates {
                ul {
                    border: 1px solid $__light__gooses;
                    border-radius: 5px;
                    margin-top: 15px;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 15px;
                        border-bottom: 1px solid $__light__gooses;
                        &:last-child {
                            border-bottom: 0;
                        }
                        h6 {
                            font-weight: $__normal;
                            font-size: $__font__size__14;
                            span {
                                font-size: $__font__size__14;
                                color: $__dark__blues;
                            }
                        }
                        .rental-charge {
                            .text-danger {
                                font-size: $__font__size__14;
                            }
                        }
                        h5 {
                            color: $__black-gray;
                            font-size: $__font__size__16;
                            font-weight: $__normal;
                        }
                        &.total-rate {
                            h6 {
                                font-size:$__font__size__15;
                                font-weight: $__semibold;
                            }
                            h5 {
                                font-size:$__font__size__15;
                                font-weight: $__semibold;
                            }
                        }
                    }
                }
            }
            .location-address-info {
                li {
                    h6 {
                        font-weight: $__semibold;
                        font-size: $__font__size__15;
                        margin-bottom: 5px;
                        color: $__black-gray;
                    }
                    p {
                        color: $__gray_light;
                        font-size: $__font__size__16;
                        & +p{
                            margin-top: 3px;
                        }
                    }
                    & +li {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .total-rate-card {
        .vehicle-total-price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $__grey;
            border-radius: 5px;
            padding: 24px;
            h5 {
                font-size: $__font__size__18;
                color: $__white;
            }
            span {
                font-size: $__font__size__24;
                color: $__white;
                font-weight: $__semibold;
            }
        }
    }
}
.booking-information-card {
    padding: 24px;
    background: $__white;
    box-shadow: 0px 4px 24px 0px #E1E1E140;
    border-radius: 5px;
    margin-bottom: 24px;
    &.booking-type-card {
        padding-bottom: 9px;
    }
    &.payment-info-card {
        padding-bottom: 9px;
    }
    .booking-info-head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F4F4F4;
        padding-bottom: 15px;
        margin-bottom: 15px;
        span {
            background: $__primarycolor;
            width: 26px;
            height: 26px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $__white;
            flex-shrink: 0;
            margin-right: 10px;
        }
        h5 {
            color: $__black-gray;
            font-size: $__font__size__18;
        }
        h6 {
            color: $__gray_light;
            font-size: $__font__size__14;
            font-weight: $__normal;
        }
    }
}
.booking-radio-btns {
    display: flex;
    align-items: center;
    @include respond-below(custom575) {
        flex-direction: column;
    }
    li {
        flex: 1;
        text-align: center;
        margin-right: 15px;
        width: 100%;
        @include respond-below(custom575) {
            margin-right: 0;
        }
        & +li {
            @include respond-below(custom575) {
                margin-top: 15px;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        .booking_custom_check {
            width: 100%;
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }
            .booking_checkmark {
                border: 1px solid $__light__gooses;
                box-shadow: 0px 4px 24px 0px #E1E1E140;
                background: $__white;
                padding: 10px 15px;
                width: 100%;
                height: 100%;
                position: relative;
                padding:15px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.5s all;        
                cursor: pointer;
                .checked-title {
                    color: $__black-gray;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: 0.5s all;
                    &:before {
                        content: "";
                        border: 1px solid #DBDBDB;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: $__blue__light;
                        display: flex;
                        transition: 0.5s all;
                        margin-bottom: 10px;
                    }
                    &::after {
                        content: "";
                        width: 7px;
                        height: 7px;
                        border-radius: 20px;
                        background: $__white;
                        position: absolute;
                        top: 5px;
                        left: 50%;
                        transform: translatex(-50%);
                        opacity: 0;
                        transition: 0.5s all;
                    }
                }
            }
            input:checked ~ .booking_checkmark {
                border-color: $__dark__blues;
                background: #12738414;
                transition: 0.5s all;
                .checked-title {
                    &::before {
                        background: $__dark__blues;
                        border-color: $__dark__blues;
                        transition: 0.5s all;
                    }
                    &::after {
                        opacity: 1;
                        transition: 0.5s all;
                    }
                }
            }
        }
    }
}
.form-custom {
    label {
        color: $__black-gray;
        font-weight: $__medium;
        font-size: $__font__size__14;
        margin-bottom: 5px;
        input {
            &::placeholder {
                font-weight: $__normal;
            }
        }
    }
    .location-btn {
        font-size: $__font__size__14;
        padding: 9px 10px;
        flex-shrink: 0;
        margin-left: 15px;
    }
}
.input-block {
    .location-check {
        margin-top: 15px;
    }
    label {
        color: $__black-gray;
        font-weight: $__medium;
        font-size: $__font__size__14;
        margin-bottom: 5px;  
    }
    .form-control {
        padding: 15px 15px;
    }
    .group-img {
        position: relative;
        .input-cal-icon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: $__black-gray;
        }
    }
}
.booking-timings {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #F4F4F4;
    .form-title-head {
        margin-bottom: 15px;
        h5 {
            color: $__black-gray;
        }
    }
}
.booking-info-btns {
    padding-top: 16px;
    @include respond-below(custom575) {
        flex-direction: column;
    }
    .continue-book-btn {
        margin-left: 15px;
        @include respond-below(custom575) {
            margin-left: 0;
            margin-top: 15px;
        }
    }
}
.adons-lists {
    li {
        & +li {
            border-top: 1px solid #F4F4F4;
            margin-top: 15px;
            padding-top: 15px;
        }
        .adons-types {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include respond-below(custom575) {
                display: block;
            }
            .adon-name-info {
                flex-basis: 100%;
                min-width: 430px;
                @include respond-below(custom1199) {
                    min-width: 360px;
                }
                @include respond-below(custom767) {
                    min-width: 320px;
                }
                @include respond-below(custom575) {
                    margin-bottom: 20px;
                    min-width: 100%;
                }
                .adon-icon {
                    width: 52px;
                    height: 52px;
                    border: 1px solid #DBDBDB;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $__dark__blues;
                    font-size: 25px;
                    flex-shrink: 0;
                    margin-right: 10px;
                }
                .adon-name {
                    h6 {
                        font-size: $__font__size__16;
                        margin-bottom: 5px;
                        font-weight: $__normal;
                    }
                    a {
                        color: $__gray_light;
                        font-size: $__font__size__14;
                        transition: 0.5s all;
                        i {
                            transition: 0.5s all;
                        }
                        &:hover {
                            color: $__dark__blues;
                        }
                        &.add-info-active {
                            color: $__dark__blues;
                            i.arrow-icon {
                                transform: rotate(180deg);
                                transition: 0.5s all;
                            }
                        }
                    }
                }
            }
            .adon-price {
                font-size: $__font__size__14;
                color: $__black-gray;
                flex-basis: 40%;
                @include respond-below(custom575) {
                    margin-right: 30px;
                }
            }
            .remove-adon-btn {
                padding: 9px 10px;
                display: inline-flex;
                align-items: center;
                flex-basis: 20%;
                font-weight: $__medium;
                min-width: 105px;
            }
            .add-addon-btn {
                padding: 9px 10px;
                display: inline-flex;
                align-items: center;
                flex-basis: 20%;
                border: 2px solid #DBDBDB;
                justify-content: center;
                font-weight: $__medium;
                color: $__gray_light;
                min-width: 105px;
            }
        }
        .more-adon-info {
            p {
               padding-left: 65px; 
               font-size: $__font__size__14;
               color: $__gray_light;
               margin-top: 15px;
            }
        }
        .view-adon-btn {
            color: $__dark__blues;
            font-size: $__font__size__15;
            font-weight: $__semibold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.view-more-adons {
            ul {
                li {
                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
.acting-driver-list {
    >li {
        border: 1px solid #F4F4F4;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-below(custom575) {
            display: block;
        }
        & +li {
            margin-top: 15px;
        }
        .driver-profile-info {
            display: flex;
            align-items: center;
            @include respond-below(custom575) {
                margin-bottom: 20px;
            }
            .driver-profile {
                width: 48px;
                height: 48px;
                display: flex;
                border-radius: 5px;
                flex-shrink: 0;
                margin-right: 10px;
                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }
            .driver-name {
                h5 {
                    font-size: $__font__size__16;
                    font-weight: $__normal;
                    margin-bottom: 5px;
                    color: $__black-gray;
                }
                ul {
                    display: flex;
                    align-items: center;
                    @include respond-below(custom575) {
                        display: block;
                    }
                    li {
                        font-size: $__font__size__14;
                        color: $__gray_light;
                        line-height: normal;
                        & +li {
                            border-left: 1px solid $__light__gooses;
                            padding-left: 10px;
                            margin-left: 10px;
                            @include respond-below(custom575) {
                                border-left: 0;
                                padding-left: 0;
                                margin-left: 0;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
        .select-driver-btn {
            padding: 9px 10px;
            display: inline-flex;
            align-items: center;
            border: 2px solid #DBDBDB;
            justify-content: center;
            font-weight: $__medium;
            color: $__gray_light;
            min-width: 100px;
        }
        .remove-driver-btn {
            padding: 9px 7px;
            min-width: 100px;
        }
    }
}
.profile-uploader {
    position: relative;
    width: 100%;
    min-height: 180px;
    border: 2px dashed #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    row-gap: 10px;
    flex-direction: column;
    input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    .drag-upload-btn {
        font-size: $__font__size__14;
        color: $__gray__lightdark;
        text-align: center;
        .upload-btn {
            background: $__primarycolor;
            font-size: $__font__size__15;
            font-weight: $__medium;
            color: $__white;
            display: block;
            margin-bottom: 15px;
            padding: 10px 15px;
            border-radius: 5px;
        }
    }
}
.img-size-info {
    font-size: $__font__size__14;
    color: $__gray__lightdark;
    padding-left: 15px;
    margin-top: 15px;
}
.book-our-drivers {
   .title-head {
    font-size: $__font__size__16;
    margin: 15px 0 10px;
    color: $__black;
   }
    .acting-driver-list {
        .change-driver {
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid #F4F4F4;

        }
        >li {
            .driver-profile-info {
                .driver-name {
                    ul {
                        li {
                            & +li {
                                padding-left: 0;
                                border-left: 0;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.coupon-close {
    span {
        width: 16px;
        height: 16px;
        background: $__dangered;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $__white;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.apply-coupon-btn {
    padding: 9px 10px;
    font-size: $__font__size__14;
}
.payment-method-types {
    h5 {
        font-size: $__font__size__18;
        margin-bottom: 20px;
        color: $__black-gray;
    }
    ul {
        display: flex;
        align-items: center;
        @include respond-below(custom575) {
            flex-direction: column;
        }
        li {
            flex: 1;
            width: 100%;
            margin-right: 20px;
            margin-bottom: 15px;
            @include respond-below(custom575) {
                margin-right: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            .payment_custom_check {
                width: 100%;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                .payment_checkmark {
                    border: 2px solid $__light__vampire;
                    background: $__light__vampire;
                    padding: 10px 15px;
                    width: 100%;
                    height: 100%;
                    min-height: 100px;
                    position: relative;
                    padding: 8px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.5s all;        
                    cursor: pointer;
                    .checked-title {
                        color: $__black-gray;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        transition: 0.5s all;
                    }
                }
                input:checked ~ .payment_checkmark {
                    border-color: $__primarycolor;
                    background: $__white;
                    transition: 0.5s all;
                    .checked-title {
                        &::before {
                            background: $__dark__blues;
                            border-color: $__dark__blues;
                            transition: 0.5s all;
                        }
                        &::after {
                            opacity: 1;
                            transition: 0.5s all;
                        }
                    }
                }
            }
        }
    }
    &.payments-cards-types {
        padding-top: 20px;
        margin-top: 5px;
        border-top: 1px solid #F4F4F4;
        margin-bottom: 10px;
        ul {
            @include respond-below(custom767) {
                flex-direction: column;
            }
            li {
                .payment_custom_check {
                    .payment_checkmark {
                        min-height: 60px;
                        border-width: 1px;
                        background-color: $__white;
                        .checked-title {
                            font-size: $__font__size__15;
                            font-weight: $__semibold;
                            img {
                                margin-right: 10px;
                            }
                          
                        }
                        small {
                            font-size: $__font__size__15;
                            font-weight: $__semibold;
                            color: $__black-gray;
                            span {
                                display: block;
                                font-size: $__font__size__14;
                                font-weight: $__normal;
                                color: $__gray_light;
                            }
                        }
                    }
                }
            }
        }
    }
}
.add-new-cards {
    padding-bottom: 15px;
    .title-head {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #F4F4F4;
    }
}
.add-new-cards {
    display: none;
}
#sign_in_modal {
    .modal-dialog {
        max-width: 635px;
    }
    .modal-content {
        padding: 50px;
        .login-wrapper {
            padding: 0;
            .loginbox {
                box-shadow: none;
                .login-auth {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}
.coupen-applied-offter {
    background: #1FBC2F1A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $__font__size__14;
    color: $__success__light;
    border: 1px solid $__success__light;
    padding: 6px 10px;
    line-height: normal;
    border-radius: 5px;
    margin-top: 15px;
    i {
        font-size: $__font__size__20;
        margin-right: 8px;
    }
}